import React, { Component } from "react"


class DiscoverCta extends Component {

  constructor(props) {
    super(props)

    this.state = { isDiscovered: false }
  }

  render() {

    const { isDiscovered } = this.state
    const { message, hiddenMessage } = this.props

    return (
      <>
        {isDiscovered ? (
          <div id="discover-cta" className="discovered">
            <span>{hiddenMessage}</span>
          </div>
        ) : (
          <div id="discover-cta" onClick={() => this.setState({ isDiscovered: true })}>
            <span>{message}</span>
          </div>
        )}
      </>
    )
  }
}

export default DiscoverCta

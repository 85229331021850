import React from "react"
import {MapContainer, Circle, TileLayer} from "react-leaflet"

const Map = ({latitude, longitude, zoom}) => {
  return(
    <MapContainer center={[latitude, longitude]} zoom={zoom} scrollWheelZoom={false} id="map-container">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Circle center={[latitude, longitude]} pathOptions={{ fillColor: 'blue' }} radius={200} />
    </MapContainer>
  )
}

export default Map



